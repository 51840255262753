export const verify = {
    methods:{
        verifyData(){
            // if(!this.defaultVal.name){
            //     this.$message.error('请填写题目')
            //     return false
            // }
            return true
        },
        resetData(){
            this.defaultVal={
                single_choice:{
                    title:'',
                    titleImg:'',
                    A:'',
                    B:'',
                    C:'',
                    D:'',
                    consult:'A',
                    consultImg:'',
                    analysis:'',
                    point:'',
                },
                multiple_choice:{
                    title:'',
                    titleImg:'',
                    A:'',
                    B:'',
                    C:'',
                    D:'',
                    consult:[],
                    consultImg:'',
                    analysis:'',
                    point:''
                },
                fill:{
                    title:'',
                    titleImg:'',
                    consult:'',
                    consultImg:'',
                    analysis:'',
                    point:''
                },
                jud_question:{
                    title:'',
                    titleImg:'',
                    consult: '1',
                    consultImg:'',
                    consultCont:'',
                    analysis:'',
                    point:''
                },
                answer:{
                    title:'',
                    titleImg:'',
                    consult: '',
                    consultImg:'',
                    analysis:'',
                    point:''
                },
                material:{
                    title:'',
                    titleImg:'',
                    consultImg:'',
                    consult: '',
                    analysis:'',
                    point:''
                }
            }
        },
    }
}
