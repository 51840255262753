<template>
    <a-drawer
      title="试题排序"
      placement="right"
      @close="onClose"
      :width="400"
      :visible="visible"
      destroyOnClose
    >
    <template v-if="data.length">
        <draggable tag="ul" v-model="data">
          <div v-for="(item,index) in data" :key="index" >
            <div class="sTitle">
              <span>{{num_zn[index]}}、</span>
              <span>{{taskType(item.type)}}</span>
            </div>
            <draggable tag="ul" v-model="item.data">
              <li v-for="( el, indexS ) in item.data" :key="indexS" class="sortlist"><span>{{indexS+1}}、</span>{{el.title}}</li>
            </draggable>
          </div>
        </draggable>

        <div style="padding:10px 0;text-align:center">
            <a-button class="btn" style="width:120px" @click="submit">确定</a-button>
        </div>
    </template>
    </a-drawer>
</template>
<script>
// import draggable from 'vuedraggable'
import { num_zn, taskType } from '@/libs/transform'
export default {
    name: 'soltList',
    props:['visible','taskTypes'],
    // components:{ draggable },
    data(){
        return{
            num_zn,
            data:[]
        }
    },
    watch:{
        visible(val){
            if(val){
                this.data = this._.cloneDeep(this.taskTypes)
            }
        }
    },
    methods:{
        taskType,
        onClose(){
            this.$emit('close' , false)
        },
        sortMaterial(){
            let marterArr = this.data.filter(el=>el.type == 'material')
            if(marterArr.length){
                marterArr.forEach((item,index)=>{
                    // types重组去重
                    let arrs =  []
                    this._.uniq(item.data.map(dd=>dd.type)).forEach((el,indexs)=>{
                        arrs.push(item.materialTask.filter( element=>element.type == el)[0])
                    })
                    item.materialTask = arrs
                })
            }
        },
        submit(){
            this.sortMaterial()
            console.log(this.data)
            this.$emit('soltList',this.data)
            this.onClose()
        }
    }
}
</script>
<style lang="less" scoped>
    .sTitle{
        font-size: 16px;
        line-height: 40px
    }
    .sortlist{
        padding:5px 10px;
        background: #eee;
        margin-bottom: 5px;
        cursor: pointer;
    }
</style>
